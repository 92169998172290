import Vue from 'vue'
import Router from 'vue-router'
import http from '@/utils/request'
import Cookies from 'js-cookie'



Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/test',
      name: 'test',
      component: () => import('@/views/test'),
      meta: {
        requireAuth: true // 添加该字段，表示进入这个路由是需要登录的
      }
    },
    {
      path: '/',
      redirect: '/login' // 将默认路由重定向到login页
    },
    
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/pages/login')
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/home'),
      meta: {
        requireAuth: true // 添加该字段，表示进入这个路由是需要登录的
      }
    },
    {
      path: '/exam',
      name: 'exam',
      component: () => import('@/views/exam'),
      meta: {
        requireAuth: true // 添加该字段，表示进入这个路由是需要登录的
        
      }
    }, {
      path: '/errorinfo',
      name: 'errorinfo',
      component: () => import('@/views/pages/errorinfo'),
      meta: {
        requireAuth: true // 添加该字段，表示进入这个路由是需要登录的
        
      }
    },
    {
      path: '/stuform',
      name: 'stuform',
      component: () => import('@/views/stuform'),
      
    },
    {
      path: '/success',
      name: 'success',
      component: () => import('@/views/success'),
      
    },
    {
      path: '/bind',
      name: 'bind',
      component: () => import('@/views/bind'),
    },
    {
      path: '/bindsuccess',
      name: 'bindsuccess',
      component: () => import('@/views/bindsuccess'),
      
    },
   
  ]
})
router.beforeEach((to, from, next) => {
  const requireAuth = to.matched.some(record => record.meta.requireAuth);
  const stuId = sessionStorage.getItem('stuId');
  const countdown = sessionStorage.getItem('countdown');
  if (to.path === '/login') {
    if (stuId) {
      next('/home');
    } else {
      next();
    }
  }else if(to.path === '/home'){
    if(countdown){
      next('/exam');
    }else if(!stuId){
      next('/login');
    }else{
      next();
    }
  }else {
    if (requireAuth && !stuId) {
      next('/login');
    } else {
      next();
    }
  }
});





export default router
